import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, IconButton } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useDispatch,useSelector} from 'react-redux';

import { deleteClient } from '../../actions/clientActions';
import { useSnackbar } from 'react-simple-snackbar';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { withStyles } from "@mui/styles";
import {
 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

const Customers = ({ clients, setOpen, setCurrentId }) => {
  const [openSnackbar, closeSnackbar] = useSnackbar()
  const dispatch = useDispatch();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  
  const onDeleteClick = (id) => {
    //if (confirm('Are you sure want to delete?')) {
    // dispatch(deleteClient(id, openSnackbar));
  
    setDeleteOpen(true);
    setDeleteId(id);
    //}
  };


  const handleEdit = (id) => {
    setOpen(true);
    setCurrentId(id);
    
  }
  const handleClose = () => {
    setDeleteOpen(false);
  };

  const deleteDialog = () => {
    dispatch(deleteClient(deleteId, openSnackbar));
    handleClose();
  };


  const columns = [
    { field: 'id', headerName: 'ID', },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'address', headerName: 'Address', flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        const onClick = (id) => {
          handleEdit(id);
        };

        return <IconButton onClick={() => onClick(params.id)} size="large">
          <BorderColorIcon style={{ width: '20px', height: '20px' }} />
        </IconButton>;
      }
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        return <IconButton
          onClick={() => onDeleteClick(params.id)}
          size="large" >
          <DeleteOutlineRoundedIcon style={{ width: '20px', height: '20px' }} />
        </IconButton >
      }
    }

  ];

  if (!clients)
    return <>Loading...</>
  const rows = clients.map((item, index) => {
    return {
      id: item._id,
      name: item.name,
      phone: item.phone,
      email: item.email,
      address: item.address1,
    }
  });

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: "#3f51b5",
      marginLeft: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "white",
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });



  return (
    <React.Fragment>
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={deleteOpen}
      fullWidth
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{ paddingLeft: "20px", color: "white" }}
      >
        Alert
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            textAlign: "left",
            color: "black",
            fontSize: "medium",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
         Are you sure want to delete Customer? 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          size="large"
          variant="contained"
        >
          No
        </Button>
        <Button
          onClick={deleteDialog}
          color="primary"
          size="large"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>

    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
    // checkboxSelection
    />
    </React.Fragment>
  );
}

export default Customers;