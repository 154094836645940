import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import Avatar from "@mui/material/Avatar";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ListAltIcon from "@mui/icons-material/ListAlt";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 450,
    // backgroundColor: "#EEEEEE",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

export default function ProfileDetail({ profiles }) {
  const classes = useStyles();

  return (
    <>
      <div style={{ maxWidth: '300px' }}>
        <Avatar
          alt={profiles?.businessName}
          src={profiles?.logo}
          className={classes.large}
        />
      </div>
      <List className={classes.root}>
        <ListItem>
          <BusinessCenterIcon style={{ marginRight: "20px", color: "gray" }} />
          <ListItemText primary={profiles?.businessName} secondary="" />
        </ListItem>

        <ListItem>
          <LocationOnIcon style={{ marginRight: "20px", color: "gray" }} />
          <ListItemText primary={profiles?.contactAddress1 + ', ' + profiles?.contactAddress2} secondary="" />
        </ListItem>
        {/* 
        <ListItem>
          <LocationOnIcon style={{ marginRight: "20px", color: "gray" }} />
          <ListItemText primary={profiles?.contactAddress2} secondary="" />
        </ListItem> */}

        <ListItem>
          <ListAltIcon style={{ marginRight: "20px", color: "gray" }} />
          <ListItemText primary={profiles?.gstNumber} secondary="" />
        </ListItem>

        <ListItem>
          <ApartmentIcon style={{ marginRight: "20px", color: "gray" }} />
          <ListItemText primary={profiles?.state?.name} secondary="" />
        </ListItem>

        <ListItem>
          <GpsFixedIcon style={{ marginRight: "20px", color: "gray" }} />
          <ListItemText primary={profiles?.postalCode} secondary="" />
        </ListItem>

        <ListItem>
          <PhoneInTalkIcon style={{ marginRight: "20px", color: "gray" }} />
          <ListItemText primary={profiles?.phoneNumber} secondary="" />
        </ListItem>

        <ListItem>
          <AlternateEmailIcon style={{ marginRight: "20px", color: "gray" }} />
          <ListItemText primary={profiles?.email} secondary="" />
        </ListItem>

        <ListItem>
          <AccountBalanceWalletRoundedIcon
            style={{ marginRight: "20px", color: "gray" }}
          />
          <ListItemText primary={profiles?.paymentDetails} secondary="" />
        </ListItem>
      </List>
    </>
  );
}
