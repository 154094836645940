import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import moment from 'moment'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useSnackbar } from "react-simple-snackbar";
import { withStyles } from "@mui/styles";
import { deleteProduct } from "../../actions/productActions";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DataGridContainer } from "../Shared/DataGrid";
import { dataGridDefaultProps } from "../../utils/utils";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },

  tablecell: {
    fontSize: "16px",
  },
}));

const Products = ({ setOpen, setCurrentId, products }) => {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(products.length);
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const dispatch = useDispatch();

  const handleEdit = (selectedInvoice) => {
    // console.log(selectedInvoice)
    setOpen(true);
    setCurrentId(selectedInvoice);
  };

  const onDeleteClick = (id) => {
    //if (confirm('Are you sure want to delete?')) {
    //setOpen(true);
    // console.log(products)
    setDeleteOpen(true);
    setDeleteId(id);

    //}
  };
  const handleClose = () => {
    setDeleteOpen(false);
  };

  const deleteDialog = () => {

    dispatch(deleteProduct(deleteId, openSnackbar));
    handleClose();
  };

  const columns = [
    //{ field: "id", headerName: "ID" },
    { field: "title", headerName: "Title", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "unit", headerName: "Unit", flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        const onClick = (id) => {
          handleEdit(id);
        };

        return (
          <IconButton onClick={() => onClick(params.id)} size="large">
            <BorderColorIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => onDeleteClick(params.id)} size="large">
            <DeleteOutlineRoundedIcon
              style={{ width: "20px", height: "20px" }}
            />
          </IconButton>
        );
      },
    },
  ];

  if (!products) return <>Loading...</>;

  const rows = products.map((item, index) => {
    return {
      id: item._id,
      title: item.title,
      price: item.price,
      hsnCode: item.hsnCode,
      unit: item.unit,
    };
  });


  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: "#3f51b5",
      marginLeft: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "white",
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={deleteOpen}
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ paddingLeft: "20px", color: "white" }}
        >
          Alert
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              textAlign: "left",
              color: "black",
              fontSize: "medium",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Are you sure want to delete product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            size="large"
            variant="contained"
          >
            No
          </Button>
          <Button
            onClick={deleteDialog}
            color="primary"
            size="large"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <DataGridContainer>
        <DataGrid
          rows={rows}
          columns={columns}
          {...dataGridDefaultProps}
        />
      </DataGridContainer>
    </React.Fragment>
  );
};

export default Products;
