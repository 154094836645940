import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';



const FabButton = () => {

  const location = useLocation()
  const mainButtonStyles = { backgroundColor: '#3f51b5' }
  const [open, setOpen] = useState(false)
  const [openProduct, setOpenProduct] = useState(false)


  // if(location.pathname === '/invoice') return null

  return (
    <div>
      {/* <AddClient setOpen={setOpen} open={open} /> */}
      <AddProduct setOpen={setOpenProduct} open={openProduct} />
      <Fab
        mainButtonStyles={mainButtonStyles}
        icon={<AddIcon />}
        alwaysShowTitle={true}
      >
        <Action
          text="New Product"
          onClick={() => setOpenProduct((prev) => !prev)}
        >
          <AssignmentIcon />
        </Action>

        {location.pathname !== '/invoice' && (
          <Action
            text="New Invoice"
            // onClick={() =>  history.push(`/invoice`)}
            onClick={() => window.location.href = '/invoice'}
          >
            <CreateIcon />
          </Action>
        )}

        <Action
          text="New Customer"
          onClick={() => setOpen((prev) => !prev)}
        >
          <PersonAddIcon />
        </Action>

      </Fab>
    </div>
  )
}

export default FabButton
