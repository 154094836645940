import React from 'react'
import styles from './Settings.module.css'
import Form from './Form/Form'
import { useHistory } from 'react-router-dom'

const Settings = () => {

    const history = useHistory()
    const user = JSON.parse(localStorage.getItem('profile'))


    if (!user) {
        history.push('/login')
    }


    return (
        <Form user={user} />
    )
}

export default Settings
