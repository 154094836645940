import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { initialState } from "../../initialState";
import { getInvoice } from "../../actions/invoiceActions";
import { convertToWord, toCommas } from "../../utils/utils";
import { useHistory } from "react-router-dom";
// import {dateFormat} from 'dateformat';
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";
import { getProductsByUser } from "../../actions/productActions";
import { useReactToPrint } from "react-to-print";
import { Button, Grid } from "@mui/material";
import Loading from "../Shared/Loading";

const MAX_ITEMS = 18;

export const InvoicePrint = React.forwardRef((props, ref) => {
  const location = useLocation();
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [rates, setRates] = useState(0);
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [client, setClient] = useState([]);
  const [type, setType] = React.useState("");
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState({});
  const { id } = useParams();
  const { invoice } = useSelector((state) => state.invoices);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sendStatus, setSendStatus] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(null);
  // eslint-disable-next-line
  const { products } = useSelector((state) => state.products.products);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const user = JSON.parse(localStorage.getItem("profile"));
  const newProd = products;

  const [pages, setPages] = useState([]);

  const isLoading = useSelector((state) => state.invoices.isLoading);

  useEffect(() => {
    dispatch(getInvoice(id));
  }, [id, dispatch, location]);

  useEffect(() => {
    dispatch(
      getProductsByUser({ search: user?._id })
    );
  }, [dispatch]);

  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  useEffect(() => {
    if (invoice) {
      setInvoiceData(invoice);
      setRates(invoice.rates);
      setClient(invoice.client);
      setType(invoice.type);
      setStatus(invoice.status);
      setSelectedDate(invoice.dueDate);
      setVat(invoice.vat);
      setCurrency(invoice.currency);
      setSubTotal(invoice.subTotal);
      setTotal(invoice.total);
      setCompany(invoice?.businessDetails?.data);

      setPages(chunk(invoice.items, MAX_ITEMS));
    }
  }, [invoice]);

  //Get the total amount paid
  let totalAmountReceived = 0;
  for (var i = 0; i < invoice?.paymentRecords?.length; i++) {
    totalAmountReceived += Number(invoice?.paymentRecords[i]?.amountPaid);
  }

  const editInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };

  const createAndDownloadPdf = () => {
    setDownloadStatus("loading");
    axios
      .post(`${"http://localhost:3000"}/create-pdf`, {
        name: invoice.client.name,
        address: invoice.client.address,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.dueDate,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),
        total: toCommas(invoice.total),
        type: invoice.type,
        vat: invoice.vat,
        items: invoice.items,
        status: invoice.status,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        company: company,
      })
      .then(() =>
        axios.get(`${"http://localhost:3000"}/fetch-pdf`, {
          responseType: "blob",
        })
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        saveAs(pdfBlob, "invoice.pdf");
      })
      .then(() => setDownloadStatus("success"));
  };

  //SEND PDF INVOICE VIA EMAIL
  const sendPdf = (e) => {
    e.preventDefault();
    setSendStatus("loading");
    axios
      .post(`${"http://localhost:3000"}/send-pdf`, {
        name: invoice.client.name,
        address: invoice.client.address,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.dueDate,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),
        total: toCommas(invoice.total),
        type: invoice.type,
        vat: invoice.vat,
        items: invoice.items,
        status: invoice.status,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        link: `${process.env.REACT_APP_URL}/invoice/${invoice._id}`,
        company: company,
      })
      // .then(() => console.log("invoice sent successfully"))
      .then(() => setSendStatus("success"))
      .catch((error) => {
        // console.log(error);
        setSendStatus("error");
      });
  };
  if (isLoading) {
    return <Loading />
  }

  return (
    <div ref={ref} className="invoice-print">
      <div class="book">
        {
          pages.map((items, pageIndex) => {
            return (
              <div key={pageIndex} class="page">
                <div class="subpage">
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={3} style={{ textAlign: "center", border: 'solid black 1px' }}>
                          <dl>
                            <dt>
                              <h2 style={{ padding: "5px" }}>
                                {" "}
                                {company?.name}
                              </h2>
                            </dt>
                          </dl>
                        </td>
                      </tr>
                      <tr>
                        <td width={"60%"} style={{ border: 'solid black 1px' }}>
                          <dl>
                            <dt className="light-title">M/s.</dt>
                            <dl className="heading-line">
                              <b> {client?.name} </b>
                            </dl>

                            <dt>{client?.address1}</dt>
                          </dl>
                        </td>
                        <td width={"20%"} style={{ border: 'solid black 1px' }}>
                          <dl>
                            <dt className="light-title">No.</dt>
                            <dd className="heading-line">{invoice.invoiceNumber}</dd>
                          </dl>
                        </td>
                        <td style={{ border: 'solid black 1px' }}>
                          <dl>
                            <dt className="light-title"> Date</dt>
                            <dd className="heading-line">
                              {invoice.invoiceDate && (
                                <>
                                  {moment(invoice.invoiceDate).format("DD/MM/YYYY")}
                                </>
                              )}
                            </dd>
                          </dl>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="items-container" style={{ border: 'solid black 1px', padding: "0px !important" }}>
                          <table className="invoice-items-table">
                            <tbody>
                              <tr className="invoice-items-table-tr-first">
                                <td>S No.</td>
                                <td>Description of Goods</td>
                                <td>Quantity</td>
                                <td>Unit</td>
                                <td>Rate</td>
                                <td className="currency">Amount</td>
                              </tr>
                              {/* {pageIndex != 0 && (
                                <tr >
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td style={{ fontWeight: "bold" }}>B/F --- </td>
                                  <td
                                    className="currency"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {toCommas(
                                      pages[pageIndex - 1].reduce(
                                        (acc, curr) =>
                                          parseFloat(acc ?? 0) +
                                          parseFloat(curr.amount),
                                        0
                                      )
                                    )}
                                  </td>
                                </tr>
                              )} */}

                              {items.map((itemFields, index) => (
                                <tr key={itemFields + index} className="item-row">
                                  <td>{pageIndex * MAX_ITEMS + index + 1}</td>
                                  <td> {itemFields.itemName}</td>
                                  <td className="currency">
                                    {itemFields?.quantity}
                                  </td>
                                  <td>{itemFields.unit}</td>
                                  <td className="currency">
                                    {toCommas(itemFields.price)}
                                  </td>
                                  <td className="currency">
                                    {toCommas(
                                      itemFields?.quantity * itemFields.price
                                    )}
                                  </td>
                                </tr>
                              ))}
                              {Array.from(
                                Array(MAX_ITEMS - items.length).keys()
                              ).map((x) => {
                                return (
                                  <tr key={x}>
                                    <td style={{ color: "#fff" }}>{x}</td>
                                    <td style={{ color: "#fff" }}>{x}</td>
                                    <td style={{ color: "#fff" }}>{x}</td>
                                    <td style={{ color: "#fff" }}>{x}</td>
                                    <td style={{ color: "#fff" }}>{x}</td>
                                    <td style={{ color: "#fff" }}>{x}</td>
                                  </tr>
                                );
                              })}
                              {pageIndex != pages.length - 1 && (
                                <>
                                  <tr>
                                    <td style={{ color: "#fff" }} colSpan={5}>
                                      a
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ color: "#fff" }} colSpan={5}>
                                      a
                                    </td>
                                  </tr>
                                  <tr
                                    className="invoice-total-tr first"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <td
                                      style={{ textAlign: "right" }}
                                      colSpan={5}
                                    >
                                      Sub Total
                                    </td>
                                    <td className="currency">
                                      {toCommas(
                                        items.reduce(
                                          (acc, curr) =>
                                            parseFloat(acc ?? 0) +
                                            parseFloat(curr.amount),
                                          0
                                        )
                                      )}

                                      <p> C/F to Next Page</p>
                                    </td>
                                  </tr>
                                </>
                              )}
                              {pageIndex == pages.length - 1 && (
                                <>
                                  <tr
                                    className="invoice-total-tr first"
                                    style={{ fontWeight: "bold", borderBottom: "1px solid" }}
                                  >
                                    <td></td>
                                    <td></td>
                                    <td>Total: {invoice.items.reduce((acc, curr) => acc + parseInt(curr.quantity), 0)} </td>
                                    <td></td>
                                    <td
                                      style={{ textAlign: "right" }}

                                    >
                                      Sub Total
                                    </td>
                                    <td className="currency">
                                      {toCommas(invoiceData.subTotal)}
                                    </td>
                                  </tr>
                                  <tr className="invoice-total-tr ">
                                    <td
                                      style={{ textAlign: "right" }}
                                      colSpan={5}
                                    >
                                      Freight
                                    </td>
                                    <td className="currency">
                                      {toCommas(invoiceData.freight)}
                                    </td>
                                  </tr>
                                  <tr className="invoice-total-tr ">
                                    <td
                                      style={{ textAlign: "right" }}
                                      colSpan={5}
                                    >
                                      GST
                                    </td>
                                    <td className="currency">
                                      {toCommas(invoiceData.gst)}
                                    </td>
                                  </tr>
                                  <tr className="invoice-total-tr first ">
                                    <td colSpan={4} style={{ textAlign: "left" }}>
                                      <dl>
                                        <dt className="light-title">
                                          Amount in words
                                        </dt>
                                        <dd
                                          style={{ textTransform: "capitalize" }}
                                        >
                                          {pageIndex != pages.length - 1 &&
                                            convertToWord(
                                              items.reduce(
                                                (acc, curr) =>
                                                  parseFloat(acc ?? 0) +
                                                  parseFloat(curr.amount),
                                                0
                                              )
                                            )}

                                          {pageIndex == pages.length - 1 &&
                                            convertToWord(invoiceData.total)}
                                        </dd>
                                      </dl>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Total
                                    </td>
                                    <td
                                      className="currency"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {toCommas(invoiceData.total)}
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>


                      <tr>
                        <td colSpan={2} style={{ border: 'solid black 1px' }}>
                          <dl>
                            <dt className="light-title">Notes</dt>
                            <dd style={{ textTransform: "capitalize" }}>{invoiceData.notes}</dd>
                          </dl>
                        </td>
                        <td style={{ height: "40px", border: 'solid black 1px' }} >
                          <dl>
                            <dt className="light-title">For</dt>
                            <dd style={{ textTransform: "capitalize" }}>

                            </dd>
                          </dl>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
});

export const PrintInvoice2 = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Button
          style={{ float: "right" }}
          variant="outlined"
          onClick={handlePrint}
        >
          Print
        </Button>
      </Grid>
      <Grid item xs={12}>
        <InvoicePrint ref={componentRef} />
      </Grid>
    </Grid>
  );
};
