/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  TextField,
} from "@mui/material";
import Uploader from "./Uploader";
import { getProfile, getProfiles, getProfilesByUser, updateProfile } from "../../../actions/profile";
import useStyles from "./styles";
import Input from "./Input";

import ProfileDetail from "./Profile";
import Autocomplete from "@mui/lab/Autocomplete/Autocomplete";
import states from "../../../states.json";

const Settings = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
    businessName: "",
    contactAddress1: "",
    contactAddress2: "",
    gstNumber: "",
    state: undefined,
    postalCode: "",
    logo: "",
    paymentDetails: "",
  };

  const [form, setForm] = useState(initialState);
  const [state, setState] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { profile } = useSelector((state) => state.profiles);
  console.log(profile);
  const [switchEdit, setSwitchEdit] = useState(0);
  const [required, setRequired] = useState({ required: false });
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();

  useEffect(() => {
    if (switchEdit === 1) {

      setForm(profile);
      setState(profile?.state);
    }

  }, [switchEdit]);

  useEffect(() => {
    dispatch(
      getProfile()
    );
  }, [location, switchEdit]);

  localStorage.setItem("profileDetail", JSON.stringify({ ...profile }));

  const handleSubmit = async (e) => {
    const data = { ...form, ["state"]: state };
    console.log(data)
    await dispatch(updateProfile(profile?._id, data, openSnackbar));
    setSwitchEdit(0);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const defaultProps = {
    options: states,
    getOptionLabel: (option) => option.name,
  };

  const CustomPaper = (props) => {
    return <Paper elevation={3} {...props} />;
  };

  return (
    <div>
      {switchEdit === 0 && (
        <Paper className={classes.paper} elevation={0}>
          <ProfileDetail profiles={profile} />
          <Button
            variant="outlined"
            style={{ margin: "30px", padding: "15px 30px" }}
            onClick={() => setSwitchEdit(1)}
          >
            Edit Profile
          </Button>
        </Paper>
      )}

      {switchEdit === 1 && (
        <Paper className={classes.paper} elevation={1}>
          <div
            style={{ justifyContent: "center", display: "flex" }}
          >
            {
              profile?.logo &&
              <Avatar
                style={{ width: "100px", height: "100px", justifyContent: "center", display: "flex" }}
                src={profile?.logo}
                alt=""
                className={classes.avatar}
              />
            }
          </div>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Uploader form={form} setForm={setForm} />
              <Input
                name="email"
                label="Email Address"
                handleChange={handleChange}
                type="email"
                half
                value={form?.email}
              />
              <Input
                name="phoneNumber"
                label="Phone Number"
                handleChange={handleChange}
                type="text"
                half
                value={form?.phoneNumber}
              />
              <Input
                name="businessName"
                label="Business Name"
                handleChange={handleChange}
                type="text"
                value={form?.businessName}
              />
              <Input
                name="contactAddress1"
                label="Address Line 1"
                handleChange={handleChange}
                type="text"
                value={form?.contactAddress1}
              />
              <Input
                name="contactAddress2"
                label="Address Line 2"
                handleChange={handleChange}
                type="text"
                value={form?.contactAddress2}
              />
              <Input
                name="gstNumber"
                label="Gst Number"
                handleChange={handleChange}
                type="text"
                value={form?.gstNumber}
              />
              <div
                style={{
                  width: "100%",
                  marginLeft: 7,
                  marginRight: 7,
                }}
              >
                <Autocomplete
                  {...defaultProps}
                  id={"state"}
                  value={state?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select State"
                      variant="outlined"
                      name="state"
                      required
                    // disableClearable

                    />
                  )}
                  // defaultValue={profiles.state.name}
                  // onInputChange={profiles.state?.name}
                  onChange={(event, value) => {

                    setState(value)
                  }}


                />
              </div>
              <Input
                name="postalCode"
                label="Postal Code"
                handleChange={handleChange}
                type="text"
                value={form?.postalCode}
              />
              <Input
                name="paymentDetails"
                label="Payment Details/Notes"
                handleChange={handleChange}
                type="text"
                multiline
                rows="4"

                value={form?.paymentDetails}
              />
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Update Settings
            </Button>
            <Grid container justifyContent="flex-end"></Grid>
          </form>
        </Paper>
      )
      }
    </div >
  );
};

export default Settings;
