import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useLocation } from "react-router-dom";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { deleteInvoice, getInvoicesByUser } from "../../actions/invoiceActions";
import { useSnackbar } from "react-simple-snackbar";
import { dataGridDefaultProps, toCommas } from "../../utils/utils";
import Loading from "../Shared/Loading";
import { Button, Grid } from "@mui/material";
import moment from "moment";
import PrintIcon from "@mui/icons-material/Print";
import { DataGridContainer } from "../Shared/DataGrid";
import { rowsMetaStateInitializer } from "@mui/x-data-grid/internals";

const Invoices = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("profile"));
  const data = useSelector((state) => state.invoices.invoices);

  const isLoading = useSelector((state) => state.invoices.isLoading);
  useEffect(() => {
    dispatch(getInvoicesByUser({ search: user?._id }));
  }, [location]);

  const editInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };
  // const deleteInvoiceData = (id) => {
  //   const invoiceData = data.find((item) => item._id === id);
  //   invoiceData.deletedBy = new Date().toLocaleString() + "";
  //   dispatch(deleteInvoice(id, invoiceData, openSnackbar));

  //   // console.log(invoiceData);
  // };

  if (!user) {
    history.push("/login");
  }

  if (isLoading) {
    return <Loading />;
  }

  const onAddClick = () => {
    history.push("/invoice");
  };

  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
    },
    { field: "invoiceDate", headerName: "Invoice Date" },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1,
    },
    { field: "amount", headerName: "Amount", flex: 1 },
    {
      field: "print",
      headerName: "Print",
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => history.push(`/invoice/${params.id}`)}>
            <PrintIcon />
          </IconButton>
        );
      },
    },

    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        const onClick = (id) => {
          editInvoice(id);
        };

        return (
          <IconButton onClick={() => onClick(params.id)}>
            <BorderColorIcon />
          </IconButton>
        );
      },
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = (id) => {
    //       // editInvoice(id);
    //       deleteInvoiceData(id);
    //     };

    //     return (
    //       <IconButton onClick={() => onClick(params.id)}>
    //         <DeleteOutlineRoundedIcon />
    //       </IconButton>
    //     );
    //   },
    // },
  ];
  const rows = data.map((item) => {
    return {
      id: item._id,
      invoiceNumber: item.invoiceNumber,
      customer: item.client?.name,
      amount: toCommas(item.total),
      invoiceDate: moment(item.invoiceDate).format("DD/MM/YYYY"),
    };
  });

  return (
    <React.Fragment>
      <Grid item xs={12} style={{ marginBottom: "30px" }}>
        <Button size="medium" variant="contained" onClick={onAddClick}>
          Add Invoice{" "}
        </Button>
      </Grid>

      <DataGridContainer>
        <DataGrid rows={rows} columns={columns} {...dataGridDefaultProps} />
      </DataGridContainer>
    </React.Fragment>
  );
};

export default Invoices;
