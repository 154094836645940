/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { getClientsByUser } from '../../actions/clientActions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import NoData from '../svgIcons/NoData'
import Spinner from '../Spinner/Spinner'
import Loading from '../Shared/Loading'
import Customer from './Customer.js'
import { Box, Button, Grid, Modal } from '@mui/material'
import Customers from './Customers';


const CustomersList = () => {

  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('profile'))
  const { clients } = useSelector((state) => state.clients)

  useEffect(() => {
    dispatch(getClientsByUser({ search: user?._id }));
  }, [location, dispatch])

  const isLoading = useSelector((state) => state.clients.isLoading);

  const onAddClick = (e) => {
    setOpen(true);
    setCurrentId();
  }
  if (isLoading) {
    return <Loading />
  }

  if (!clients)
    return <>Loading...</>


  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button size='medium' variant='contained' onClick={onAddClick}>Add Customer </Button>
          <Customer open={open}
            setOpen={setOpen}
            currentId={currentId}
            setCurrentId={setCurrentId} />
        </Grid>
        <Grid item xs={12}>
          <Customers
            open={open}
            setOpen={setOpen}
            currentId={currentId}
            setCurrentId={setCurrentId}
            clients={clients}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default CustomersList;

