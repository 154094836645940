import React from 'react';
import Spinner from '../Spinner/Spinner';

function Loading() {

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px' }}>
            <Spinner />
        </div>
    );
}

export default Loading;