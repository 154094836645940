import React, { useState, useEffect } from "react";
import styles from "./Invoice.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toCommas } from "../../utils/utils";
import IconButton from "@mui/material/IconButton";

import Box from '@mui/material/Box';
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { initialState } from "../../initialState";
import CachedIcon from '@mui/icons-material/Cached';
import {
  createInvoice,
  getInvoice,
  updateInvoice,
} from "../../actions/invoiceActions";
import { getClientsByUser } from "../../actions/clientActions";
import { getProductsByUser } from "../../actions/productActions";
import { getProfilesByUser } from "../../actions/profile";
import { fetchLastInvoice } from "../../api";
import Loading from "../Shared/Loading";
import { useSnackbar } from "react-simple-snackbar";
import Customer from "../Customers/Customer";

import moment from "moment";
import AddProduct from "../Products/AddProduct";



const useStyles = makeStyles(() => ({

}));

const filter = createFilterOptions();
const InvoiceKaccha = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [openSnackbar] = useSnackbar();

  const [currency, setCurrency] = useState("₹");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [openProduct, setOpenProduct] = useState();
  const [newProduct, setNewProduct] = useState();

  const today = new Date();
  const [invoiceDate, setInvoiceDate] = useState(moment(today).format('YYYY-MM-DD'));

  const [client, setClient] = useState(null);
  const [type, setType] = useState("Invoice");
  const [status, setStatus] = useState("");
  const { id } = useParams();
  const clients = useSelector((state) => state.clients.clients);
  const products = useSelector((state) => state.products.products);
  const { invoice } = useSelector((state) => state.invoices);
  const user = JSON.parse(localStorage.getItem("profile"));

  const isLoading = useSelector((state) => state.invoices.isLoading);

  useEffect(() => {
    if (id) {
      return;
    }
    (async () => {
      const lastInvoice = await fetchLastInvoice();
      if (lastInvoice) {

        const no = lastInvoice.invoiceNumber;
        if (no && no != 'NaN') {

          const numricValue = no.replace(/^\D+/g, '');
          const stringValue = no.replace(numricValue, '');

          //const n = no.replace(user.invoiceAbbr, '');
          const a = parseInt(numricValue) + 1;
          const newInv = `${stringValue}${a}`;
          setInvoiceData({
            ...invoiceData,
            invoiceNumber: newInv,
          });
        } else {
          const newInv = `${user.invoiceAbbr}1`;
          setInvoiceData({
            ...invoiceData,
            invoiceNumber: newInv,
          });
        }
      } else {
        const newInv = `${user.invoiceAbbr}1`;
        setInvoiceData({
          ...invoiceData,
          invoiceNumber: newInv,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getInvoice(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getClientsByUser({ search: user?._id }));
    dispatch(getProductsByUser({ search: user?._id }));
    dispatch(getProfilesByUser({ search: user?._id || user?._id }));
  }, [dispatch]);

  useEffect(() => {
    if (invoice && id) {
      setInvoiceData(invoice);
      setClient(invoice.client);
      setSubTotal(invoice.subTotal);
      setTotal(invoice.total);
      setInvoiceDate(invoice.invoiceDate);
    }
  }, [invoice]);


  const onAddSubmit = (values) => {
    dispatch(getProductsByUser({ search: user?._id }));
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log(newProduct, products);
  //     if (newProduct) {
  //       const selected = products.find(p => p.title == newProduct.title);
  //       console.log(selected);
  //     }
  //   }, 1000)
  // }, [products])


  const getItemAmount = (item) => {
    if (item.price && item.quantity) {
      return item.price * item.quantity;
    }
    return 0;
  };

  const handleChange = (index, e) => {
    const values = [...invoiceData.items];
    values[index][e.target.name] = e.target.value;
    values[index]["amount"] = getItemAmount(values[index]);
    setInvoiceData({ ...invoiceData, items: values });
  };


  const getValues = (values, index, value) => {
    values[index]["itemName"] = value?.title;
    values[index]["item"] = value;
    values[index]["price"] = parseFloat(value?.price);
    values[index]["unit"] = value?.unit;
    values[index]["amount"] = getItemAmount(values[index]);
    return values;
  }
  const handleChangeProduct = (index, inputValue) => {
    const values = [...invoiceData.items];
    if (!inputValue) {
      return;
    }


    if (inputValue && inputValue?.isNew) {
      setTimeout(() => {
        setNewProduct({ title: inputValue.inputValue })
        setOpenProduct(true);
      });
      return;
    }


    setInvoiceData({ ...invoiceData, items: getValues(values, index, inputValue) });

  };

  useEffect(() => {
    if (invoiceData.items?.length <= 0) {
      return;
    }
    let subTotal = invoiceData.items.reduce((acc, curr) => {
      return Number(acc) + Number(curr.amount);
    }, 0);
    setSubTotal(subTotal);
    const t = Number(invoiceData.freight) + Number(invoiceData.gst) + subTotal;
    setTotal(t);
  }, [invoiceData]);

  const handleAddField = (e) => {
    e.preventDefault();
    setInvoiceData((prevState) => ({
      ...prevState,
      items: [...prevState.items, {}],
    }));
  };



  const handleRemoveField = (index) => {
    const values = invoiceData.items;
    values.splice(index, 1);
    setInvoiceData((prevState) => ({ ...prevState, values }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (invoice && id) {
      dispatch(
        updateInvoice(
          invoice._id,
          {
            ...invoiceData,
            subTotal: subTotal,
            total: total,

            currency: currency,

            client,
            type: type,
            status: status,
            invoiceDate: invoiceDate,
          },
          history
        )
      );
    } else {
      dispatch(
        createInvoice(
          {
            ...invoiceData,
            subTotal: subTotal,
            total: total,

            currency: currency,

            client,
            type: type,
            status: status,
            paymentRecords: [],
            creator: [user?._id || user?._id],
            createdAt: new Date(),
            invoiceDate: invoiceDate,
          },
          history,
          openSnackbar
        )
      );
    }
  };

  const classes = useStyles();
  const [open, setOpen] = useState(false);


  if (!user) {
    history.push("/login");
  }
  if (isLoading) {
    return <Loading />;
  }
  if (!invoiceData.invoiceNumber) {
    return;
  }
  return (
    <div className={styles.invoiceLayout}>
      <form onSubmit={handleSubmit} className="mu-form">
        <Customer setOpen={setOpen} open={open} />

        <AddProduct
          open={openProduct}
          setOpen={setOpenProduct}
          newProduct={newProduct}
          onSubmit={onAddSubmit}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="overline"
              style={{ color: "gray" }}
              gutterBottom
            >
              Invoice Type{" "}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {/* <InvoiceType type={type} setType={setType} /> */}
              Estimate
            </Typography>
            <Typography
              variant="overline"
              style={{ color: "gray", paddingRight: "3px" }}
              gutterBottom
            >
              Bill to
            </Typography>

            {client && (
              <>
                <Typography variant="subtitle2" gutterBottom>
                  {client.name}
                </Typography>
                <Typography variant="body2">{client.email}</Typography>
                <Typography variant="body2">{client.phone}</Typography>
                <Typography variant="body2">
                  {client.address1 + ", " + client.address2 + ","}
                </Typography>
                <Typography variant="body2">
                  {client.state.name + ", " + client.postalCode}
                </Typography>
                <Button
                  color="primary"
                  size="small"
                  style={{ textTransform: "none" }}
                  onClick={() => setClient(null)}
                >
                  Change
                </Button>
              </>
            )}
            <div style={client ? { display: "none" } : { display: "block" }}>
              {
                <Autocomplete
                  single
                  options={clients}
                  getOptionLabel={(option) => option?.name || ""}
                  outline="none"
                  id="customer_name"
                  name={"customer"}
                  renderInput={(params) => (
                    <TextField
                      style={{ marginBottom: "5px" }}
                      {...params}
                      required={!invoice && true}
                      label="Select Customer"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      setClient(value);
                    }
                  }}
                />
              }
            </div>
            {!client && (
              <>
                <Grid item style={{ paddingBottom: "10px" }}>
                  <Chip
                    avatar={<Avatar>+</Avatar>}
                    label="New Customer"
                    onClick={() => setOpen(true)}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            <Typography
              variant="overline"
              style={{ color: "gray" }}
              gutterBottom
            >
              Invoice Number
            </Typography>
            <Typography variant="body2" gutterBottom>
              <TextField
                variant="standard"
                name="invoiceNumber"
                onChange={(e) => {
                  setInvoiceData({
                    ...invoiceData,
                    invoiceNumber: e?.target?.value,
                  });
                }}
                inputProps={{ min: 0, style: { textAlign: "right" } }}
                value={invoiceData.invoiceNumber}
              />
            </Typography>

            <Typography
              variant="overline"
              style={{ color: "gray" }}
              gutterBottom
            >
              Date
            </Typography>
            <Typography variant="body2" gutterBottom>
              <TextField
                variant="standard"
                type="date"
                name="invoiceDate"
                onChange={(e) => {
                  setInvoiceDate(e.target.value);
                }}
                inputProps={{ style: { textAlign: "right" }, required: true }}
                value={invoiceDate}
              />
            </Typography>
          </Grid>
        </Grid>

        <div>
          <TableContainer >
            <Table
            >
              <TableHead>
                <TableRow>
                  <TableCell>S No.</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData.items.map((itemField, index) => {
                  return (
                    <TableRow key={`${itemField.title}-${index}`}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell scope="row" style={{ width: "40%" }}>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',

                          }}
                        >
                          <Autocomplete fullWidth
                            renderOption={(props, option) => <li {...props}>{option.title}</li>}
                            options={products}
                            getOptionLabel={(option) => option.title || ""}

                            id={`product-${itemField.title}-${index}`}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                margin="normal"
                              />
                            )}
                            value={itemField.item}
                            name="itemName"
                            onChange={(e, value) =>
                              handleChangeProduct(index, value)
                            }
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              if (params.inputValue !== '') {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  title: `Add New - "${params.inputValue}"`,
                                  isNew: true
                                });
                              }

                              return filtered;
                            }}
                          />
                          <IconButton
                            onClick={() => onAddSubmit()}

                          >
                            <CachedIcon
                            />
                          </IconButton>
                        </Box>



                      </TableCell>

                      <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="quantity"
                          onChange={(e) => handleChange(index, e)}
                          value={itemField.quantity}
                          placeholder="0"
                        />{" "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="price"
                          onChange={(e) => handleChange(index, e)}
                          value={itemField.price}
                          placeholder="0"
                        />{" "}
                      </TableCell>

                      <TableCell align="right">
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="input"
                          name="unit"
                          onChange={(e) => handleChange(index, e)}
                          value={itemField.unit}
                        />
                      </TableCell>

                      <TableCell align="right">
                        <Typography variant="body2" gutterBottom>
                          {toCommas(itemField.amount)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleRemoveField(index)}

                        >
                          <DeleteOutlineRoundedIcon

                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item style={{ paddingTop: "10px" }}>
            <Chip
              avatar={<Avatar>+</Avatar>}
              label="New Item"
              onClick={handleAddField}
              variant="outlined"
            />
          </Grid>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={8}></Grid>
          <Grid item xs>
            <TableContainer component={Paper} className="tb-container">
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Summary</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>Sub total:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {" "}
                      <h4>{toCommas(subTotal)}</h4>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Freight:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {" "}
                      <TextField
                        variant="standard"
                        name="freight"
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            freight: e?.target?.value,
                          });
                        }}
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        value={invoiceData.freight}
                      ></TextField>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>GST:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <TextField
                        variant="standard"
                        name="gst"
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            gst: e?.target?.value,
                          });
                        }}
                        inputProps={{ min: 0, style: { textAlign: "right" } }}
                        value={invoiceData.gst}
                      ></TextField>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {" "}
                      <h4
                        style={{
                          color: "black",
                          fontSize: "18px",
                          lineHeight: "8px",
                        }}
                      >
                        {toCommas(total)}
                      </h4>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <div className={styles.note}>
          <h4>Note/Payment Info</h4>
          <textarea
            style={{ border: "solid 1px #d6d6d6", padding: "10px" }}
            placeholder="Provide additional details or terms of service"
            onChange={(e) =>
              setInvoiceData({ ...invoiceData, notes: e.target.value })
            }
            value={invoiceData.notes}
          />
        </div>

        <Grid container justifyContent="center">
          <Button
            variant="contained"
            style={{ justifyContentContent: "right" }}
            type="submit"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Save and Continue
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default InvoiceKaccha;
