import * as api from '../api/index'
import { AUTH, CREATE_PROFILE } from './constants'


export const signin = (formData, openSnackbar, setLoading) => async (dispatch) => {

    try {

        //login the user
        const authData = await api.signIn(formData)
        const settings = await api.fetchProfileByMasterId(authData?.data?.masterId);
        const data = { email: authData?.data?.email, loginName: authData?.data?.name, ...settings.data };
        debugger;
        dispatch({ type: AUTH, data })
        openSnackbar("Signin successfull")
        window.location.href = "/#dashboard"

    } catch (error) {

        // console.log(error?.response?.data?.message)
        openSnackbar(error?.error)
        setLoading(false)
    }
}

export const signup = (formData, openSnackbar, setLoading) => async (dispatch) => {

    try {
        //Sign up the user
        const { data } = await api.signUp(formData)
        dispatch({ type: AUTH, data })
        const { info } = await api.createProfile({ name: data?.result?.name, email: data?.result?.email, userId: data?.result?._id, masterId: data?.result.masterId, phoneNumber: '', businessName: '', contactAddress: '', logo: '', website: '' });
        dispatch({ type: CREATE_PROFILE, payload: info });
        window.location.href = "/#dashboard";
        openSnackbar("Sign up successfull")

    } catch (error) {
        console.log(error)
        openSnackbar(error?.response?.data?.message)
        setLoading(false)
    }
}



export const forgot = (formData) => async (dispatch) => {
    try {
        await api.forgot(formData)
    } catch (error) {
        console.log(error)
    }
}


export const reset = (formData, history) => async (dispatch) => {

    try {
        await api.reset(formData)
        history.push('/dashboard')

    } catch (error) {
        alert(error)
    }
}
