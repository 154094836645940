/* eslint-disable */
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { createClient, updateClient } from "../../actions/clientActions";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle as MuiDialogTitle,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";

const validationSchema = yup.object({
  name: yup.string("Name").required("Name is required"),
  phone: yup.string("Phone").required("Phone is required"),
  email: yup.string("Email").required("Email is required").email('Invalid Email.'),
  address: yup.string("Address").required("Address is required"),
  state: yup
    .string("State")
    .required("State is required"),
  postalCode: yup
    .string("Postal Code ")
    .required("Postal code is required")
});

const Customer = ({ setOpen, open, currentId, setCurrentId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const client = useSelector((state) =>
    currentId ? state.clients.clients.find((c) => c._id === currentId) : null
  );

  useEffect(() => {
    formik.setValues({});
    if (client) {
      formik.setValues(client);
    }
  }, [client]);

  const clear = () => {
    formik.setValues({});
  };
  const handleClose = () => {
    setOpen(false);
    clear();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      postalCode: "",
      state: undefined
    },
    validationSchema: validationSchema,
    validator: () => {
      console.log('aa0');
    },
    onSubmit: (values) => {

      if (values._id) {
        dispatch(updateClient(values._id, values, openSnackbar, history));
      }
      else {
        dispatch(createClient(values, openSnackbar, history));
      }
      handleClose();
    },
  });

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: "#3f51b5",
      marginLeft: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "white",
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{ paddingLeft: "20px", color: "white" }}
      >
        New Customer
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    fullWidth
                    variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="phone"
                    name="phone"
                    label="Phone"
                    fullWidth
                    variant="outlined"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="address"
                    name="address"
                    label="Address"
                    fullWidth
                    variant="outlined"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={
                      formik.touched.address && formik.errors.address
                    }
                  />
                </Grid>


                <Grid item xs={12} sm={6}>
                  <TextField
                    id="state"
                    name="state"
                    label="State"
                    fullWidth
                    variant="outlined"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="postalCode"
                    name="postalCode"
                    label="Postal Code"
                    fullWidth
                    autoComplete="shipping postal-code"
                    variant="outlined"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.postalCode &&
                      Boolean(formik.errors.postalCode)
                    }
                    helperText={
                      formik.touched.postalCode && formik.errors.postalCode
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button disabled={formik.isSubmitting}

                    size="large"
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Customer;
