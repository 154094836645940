/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { withStyles } from "@mui/styles";
import * as yup from "yup";
import { createProduct, getProductsByUser, updateProduct } from "../../actions/productActions";
import {
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { useHistory } from "react-router-dom";

import units from "../../units.json";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#3f51b5",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});

const AddProduct = ({ setOpen, open, currentId, setCurrentId, newProduct, setNewProduct, onSubmit }) => {
  const history = useHistory();
  const location = useLocation();

  const [productData, setProductData] = useState({ unit: units[0] });
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();

  const product = useSelector((state) =>
    currentId ? state.products.products.find((c) => c._id === currentId) : null
  );

  const products = useSelector((state) => state.products.products);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [errors, setErrors] = useState();

  useEffect(() => {
    formik.setValues({ unit: units[0] });
    if (product) {
      formik.setValues(product);
    }
  }, [product]);



  useEffect(() => {
    formik.setValues({ unit: units[0] });
    if (newProduct) {
      formik.setValues(newProduct);
    }
  }, [newProduct]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  const validationSchema = yup.object({
    title: yup
      .string("Title")
      .required("Title is required")
      .test(
        "Unique Title",
        "Product already exists.", // <- key, message
        function (value) {
          return !products.find((x) => product?._id != x._id && x.title.replaceAll(' ', '').replaceAll('-', '') == value.replaceAll(' ', '').replaceAll('-', ''));

        }
      ),
    price: yup.string("Price").required("Price is required"),
    unit: yup.string("Unit").required("Unit is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values) => {

      if (values._id) {
        dispatch(updateProduct(currentId, values, openSnackbar, history));
        handleClose();
      } else {
        dispatch(createProduct(values));
        handleClose();
        if (onSubmit) {
          onSubmit(values);
        }
        if (newProduct) {
          setTimeout(() => {
            dispatch(getProductsByUser());
          })
        } else {
          history.push(`/products`);
        }
      }
    },
  });

  const handleClose = () => {
    setOpen(false);

  };

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{ paddingLeft: "20px", color: "white" }}
      >
        Product
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="title"
                    name="title"
                    label="Title"
                    fullWidth
                    variant="outlined"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                  {/* <ErrorMessage
                  name='title'
                 // component='Grid'
                //  className="error-message"
                  >

                  </ErrorMessage> */}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="price"
                    name="price"
                    label="Price"
                    fullWidth
                    variant="outlined"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="unit"
                      name="unit"
                      fullWidth
                      variant="outlined"
                      value={formik.values.unit}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    >
                      {units.map((unit) => {
                        return <MenuItem value={unit}>{unit}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button
                    disabled={formik.isSubmitting}
                    size="large"
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddProduct;
