import * as api from '../api/index'

import { ADD_NEW_CLIENT, UPDATE_CLIENT, DELETE_CLIENT, FETCH_CLIENTS_BY_USER, FETCH_CLIENT, START_LOADING, END_LOADING } from './constants'


export const getClient = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchClient(id);
    dispatch({ type: FETCH_CLIENT, payload: { client: data } });

  } catch (error) {
    console.log(error);
  }
};


export const getClientsByUser = (searchQuery) => async (dispatch) => {
  dispatch({ type: START_LOADING })
  const { data: { data } } = await api.fetchClientsByUser(searchQuery)
  dispatch({ type: FETCH_CLIENTS_BY_USER, payload: data });
  dispatch({ type: END_LOADING })
}


export const createClient = (client, openSnackbar, history) => async (dispatch) => {
  debugger;
  dispatch({ type: START_LOADING })
  const { insertedId } = await api.addClient(client)
  history.push(`/customers`)
  dispatch({ type: END_LOADING })
  openSnackbar("Customer created successfully");
}


export const updateClient = (id, client, openSnackbar, history) => async (dispatch) => {
  dispatch({ type: START_LOADING })
  const data = await api.updateClient(id, client)
  dispatch({ type: END_LOADING })
  history.push(`/customers`)
  openSnackbar("Customer updated successfully")
}

export const deleteClient = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteClient(id)

    dispatch({ type: DELETE_CLIENT, payload: id })
    openSnackbar("Customer deleted successfully")
  } catch (error) {
    console.log(error)
  }
}