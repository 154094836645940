import { Card, CardContent, CardHeader, ListItem, ListItemIcon, ListItemText, ListSubheader, Container as MuiContainer } from "@mui/material"
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
//import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


import AssignmentIcon from '@mui/icons-material/Assignment';
import Header from "./Header";
import NavBar from "../NavBar/NavBar";
import FabButton from "../Fab/Fab";
import ResponsiveAppBar from "./Appbar";



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        //paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export const Container = ({ children }) => {

    const [user, setUser] = useState()

    const location = useLocation()

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])


    const classes = useStyles();
    const getTitle = () => {
        if (location.pathname.includes('invoice/')) return 'Invoice';
        return location.pathname.replace('/', '');
    }

    if (!user) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <main className={classes.content}>
                    <MuiContainer className={classes.container} style={{ maxWidth: '100%' }}>
                        {children}
                    </MuiContainer>
                </main>
            </div>);
    }
    return (
        <div className={classes.root}>
            <ResponsiveAppBar />

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <MuiContainer className={classes.container} style={{ maxWidth: '100%', marginTop: "10px", marginLeft: '10px', paddingLeft: "0px" }}>
                    <Card>
                        <CardHeader className="header-title" title={getTitle()} style={{ padding: "10px", fontSize: 'medium' }} />

                        <hr style={{ borderColor: "#eee" }} />
                        <CardContent style={{ minHeight: "calc(100vh - 200px)" }}>
                            {children}
                        </CardContent>

                    </Card>
                </MuiContainer>
                {/* <FabButton /> */}
            </main>
        </div>
    );
}
