//https://www.mongodb.com/docs/realm/web/mongodb/#std-label-web-query-mongodb

import * as Realm from "realm-web";
const {
    BSON: { ObjectId },
} = Realm;

const app = new Realm.App({ id: 'data-cppkq' });
const DATABASE_NAME = 'invo-db', DATA_SOURCE_NAME = 'Cluster0';

const COLLECTIONS = {
    INVOICES: 'invoices',
    CUSTOMERS: 'customers',
    PRODUCTS: 'products',
    USERS: 'users',
    PROFILES: 'profiles',
    COMPANIES: 'companies',
    DELETEDRECORD: 'deleted-invoices',
    INVOICESAUDIT: 'invoices-audit'
}

const login = async (loginData) => {
    if (app.currentUser && app.currentUser.id) {
        return app.currentUser.id;
    }
    const credentials = Realm.Credentials.emailPassword(loginData.email, loginData.password);
    const user = await app.logIn(credentials);
    return user.id;
}

export const isLoggedIn = () => {
    return app.currentUser;
}
const getOne = async (collection, filter = {}) => {
    const mongo = app.currentUser.mongoClient(DATA_SOURCE_NAME);
    const db = mongo.db(DATABASE_NAME);
    return await db.collection(collection).findOne(filter);
}

const get = async (collection, filter = {}) => {
    const mongo = app.currentUser.mongoClient(DATA_SOURCE_NAME);
    const db = mongo.db(DATABASE_NAME);
    //return ((await db.collection(collection).find(filter)));
    return (await db.collection(collection).find(filter, { sort: { _id: -1 } }));
}

//db.collection.find().limit(1).sort({$natural:-1}) 

const getLast = async (collection, filter = {}, sort = {}) => {
    const mongo = app.currentUser.mongoClient(DATA_SOURCE_NAME);
    const db = mongo.db(DATABASE_NAME);
    return await db.collection(collection).find(filter, { sort: sort });
}
const count = async (collection, filter = {}) => {

    const mongo = app.currentUser.mongoClient(DATA_SOURCE_NAME);
    const db = mongo.db(DATABASE_NAME);
    return await db.collection(collection).count(filter);
}
const insert = async (collection, data) => {

    const mongo = app.currentUser.mongoClient(DATA_SOURCE_NAME);
    const db = mongo.db(DATABASE_NAME);
    return await db.collection(collection).insertOne(data)
}

const update = async (collection, filter, data) => {
    const mongo = app.currentUser.mongoClient(DATA_SOURCE_NAME);
    const db = mongo.db(DATABASE_NAME);


    return await db.collection(collection).updateOne(filter, {
        $set: data
    });
}
const deleteRecord = async (collection, filter) => {
    const mongo = app.currentUser.mongoClient(DATA_SOURCE_NAME);
    const db = mongo.db(DATABASE_NAME);
    return await db.collection(collection).deleteOne(filter);
}

const getMasterId = () => {
    const data = JSON.parse(localStorage.getItem('profile'));
    return data?.masterId;
}

const API = {
    get: async (collection, filter) => {
        const data = await get(collection, filter);
        return { data: { data: data } }
    },
    getOne: async (collection, filter) => {
        const data = await getOne(collection, filter);
        return { data: data };
    },
    count: async (collection, filter) => {
        return await count(collection, filter);
    },
    insert: async (collection, data) => {
        return await insert(collection, data)
    },
    update: async (collection, filter, data) => {



        const result = await update(collection, filter, data);
        return result.modifiedCount > 0;
    },
    delete: async (collection, filter) => {
        const result = await deleteRecord(collection, filter);
        return result.deletedCount > 0;
    },

    getLastOne: async (collection, filter, sort) => {
        const data = await getLast(collection, filter, sort);
        return data.length > 0 ? data[0] : undefined;
    },

}

export const signIn = async (data) => {
    const loginData = await login(data);
    console.log(loginData);
    return await API.getOne(COLLECTIONS.USERS, { email: data.email });

};

export const signOut = async () => {
    await app.currentUser?.logOut();
}


export const signUp = async (data) => {
    return { data: data };
};

export const forgot = async (formData) => await API.post('/users/forgot', formData);
export const reset = async (formData) => await API.post('/users/reset', formData);


//Invoices

export const fetchInvoice = async (id) => await API.getOne(COLLECTIONS.INVOICES, { _id: ObjectId(id) })
export const fetchInvoicesCount = async () => await API.count(COLLECTIONS.INVOICES, { masterId: getMasterId() });

export const addInvoice = async (invoice) => await API.insert(COLLECTIONS.INVOICES, { ...invoice, masterId: getMasterId() })
export const updateInvoice = async (id, data) => {
    const prevInvoice = await fetchInvoice(id);
    await API.insert(COLLECTIONS.INVOICESAUDIT, { ...prevInvoice, auditDate: new Date(), masterId: getMasterId() })
    await API.update(COLLECTIONS.INVOICES, { _id: id }, data);
}
export const fetchInvoicesByUser = async (searchQuery) => await API.get(COLLECTIONS.INVOICES, { masterId: getMasterId() })

export const fetchLastInvoice = async () => await API.getLastOne(COLLECTIONS.INVOICES, { masterId: getMasterId() }, { createdAt: -1 })

export const deleteInvoice = async (id) => await API.delete(COLLECTIONS.INVOICES, { _id: id })
export const deletedInvoiceRecord = async (invoice) => await API.insert(COLLECTIONS.DELETEDRECORD, { ...invoice, masterId: getMasterId() })


//Products
export const fetchProduct = async (id) => await API.getOne(COLLECTIONS.PRODUCTS, { _id: ObjectId(id) })

export const addProduct = async (invoice) => await API.insert(COLLECTIONS.PRODUCTS, { ...invoice, masterId: getMasterId() })
export const updateProduct = async (id, data) => await API.update(COLLECTIONS.PRODUCTS, { _id: id }, data);
export const fetchProductsByUser = async () => await API.get(COLLECTIONS.PRODUCTS, { masterId: getMasterId() })
export const deleteProduct = async (id) => await API.delete(COLLECTIONS.PRODUCTS, { _id: id })


//Customers
export const fetchClient = async (id) => await API.get(COLLECTIONS.CUSTOMERS, { masterId: getMasterId() })
export const addClient = async (invoice) => await API.insert(COLLECTIONS.CUSTOMERS, { ...invoice, masterId: getMasterId() })
export const updateClient = async (id, data) => await API.update(COLLECTIONS.CUSTOMERS, { _id: id }, data);
export const fetchClientsByUser = async (searchQuery) => await API.get(COLLECTIONS.CUSTOMERS, { masterId: getMasterId() })
export const deleteClient = async (id) => await API.delete(COLLECTIONS.CUSTOMERS, { _id: id })



//Companies
export const fetchProfiles = async (id) => await API.get(COLLECTIONS.COMPANIES, { masterId: getMasterId() });

export const fetchProfileByMasterId = async (masterId) => await API.getOne(COLLECTIONS.COMPANIES, { masterId: masterId });
export const fetchProfile = async () => await API.getOne(COLLECTIONS.COMPANIES, { masterId: getMasterId() });
export const createProfile = async (invoice) => await API.insert(COLLECTIONS.COMPANIES, { ...invoice, masterId: getMasterId() })
export const updateProfile = async (id, data) => await API.update(COLLECTIONS.COMPANIES, { _id: id }, data);
export const fetchProfilesByUser = async (searchQuery) => await API.get(COLLECTIONS.COMPANIES, { masterId: getMasterId() })
export const deleteProfile = async (id) => await API.delete(COLLECTIONS.COMPANIES, { _id: id })



// export const fetchProfilesBySearch = async (searchQuery) => await API.get(`/profiles/search?searchQuery=${searchQuery.search || searchQuery.year || 'none'}`);
// export const fetchProfile = async (id) => await API.get(`/profiles/${id}`)
// export const fetchProfiles = async () => await API.get('/profiles');
// export const fetchProfilesByUser = async (searchQuery) => await API.get(`/profiles?searchQuery=${searchQuery.search}`)
// export const createProfile = async (newProfile) => await API.post('/profiles', newProfile);
// export const updateProfile = async (id, updatedProfile) => await API.patch(`/profiles/${id}`, updatedProfile);
// export const deleteProfile = async (id) => await API.delete(`/profiles/${id}`);

