/* eslint-disable */
import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import NoData from '../svgIcons/NoData'
import Spinner from '../Spinner/Spinner'
import AddProduct from './AddProduct'
import Products from './Products'
import { getProductsByUser } from '../../actions/productActions'
import { Button, Grid } from '@mui/material'
import Loading from '../Shared/Loading'

const ProductList = () => {

  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('profile'))
  const { products } = useSelector((state) => state.products);
  const isLoading = useSelector(state => state.products.isLoading)


  useEffect(() => {
    dispatch(getProductsByUser({ search: user?._id }));
  }, [location, dispatch])

  const onAddClick = () => {
    setOpen(true);
    setCurrentId();
  }

  if (isLoading) {
    return <Loading />
  }
  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button size='medium' variant='contained' onClick={onAddClick}>Add Product </Button>
        <AddProduct
          open={open}
          setOpen={setOpen}
          currentId={currentId}
          setCurrentId={setCurrentId}
        />

      </Grid>
      <Grid item xs={12}>


        <Products
          open={open}
          setOpen={setOpen}
          currentId={currentId}
          setCurrentId={setCurrentId}
          products={products}
        />
      </Grid>
    </Grid>
  )
}

export default ProductList

