import * as api from "../api/index";

import {
  FETCH_PRODUCTS_BY_USER,
  START_LOADING,
  END_LOADING,
  ADD_NEW_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  FETCH_PRODUCT,
} from "./constants";

export const getProduct = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  const { data } = await api.fetchProduct(id);
  dispatch({ type: FETCH_PRODUCT, payload: data });
};

export const getProductsByUser = () => async (dispatch, getState) => {
  dispatch({ type: START_LOADING });
  const {
    data: { data },
  } = await api.fetchProductsByUser();
  dispatch({ type: FETCH_PRODUCTS_BY_USER, payload: data });
  dispatch({ type: END_LOADING });
};

export const createProduct = (product) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  const { insertedId } = await api.addProduct(product);
  dispatch({ type: END_LOADING });
};
export const updateProduct =
  (id, product, openSnackbar, history) => async (dispatch) => {
    dispatch({ type: START_LOADING });

    const data = await api.updateProduct(id, product);

    history.push(`/products`);
    dispatch({ type: END_LOADING });

    openSnackbar("Product updated successfully");
  };

export const deleteProduct = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteProduct(id);

    dispatch({ type: DELETE_PRODUCT, payload: id });
    openSnackbar("Product deleted successfully");
  } catch (error) {
    console.log(error);
  }
};
