import * as api from '../api/index';

import { ADD_NEW, UPDATE, DELETE, GET_INVOICE, FETCH_INVOICE_BY_USER, START_LOADING, END_LOADING } from './constants'

export const getInvoicesByUser = (searchQuery) => async (dispatch) => {
    dispatch({ type: START_LOADING })
    const { data: { data } } = await api.fetchInvoicesByUser(searchQuery)
    dispatch({ type: FETCH_INVOICE_BY_USER, payload: data });
    dispatch({ type: END_LOADING })
}


export const getInvoice = (id) => async (dispatch) => {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchInvoice(id)
    const businessDetails = await api.fetchProfile();
    const invoiceData = { ...data, businessDetails }
    dispatch({ type: GET_INVOICE, payload: invoiceData })
    dispatch({ type: END_LOADING });
}

export const createInvoice = (invoice, history, openSnackbar) => async (dispatch) => {
    dispatch({ type: START_LOADING })
    const { insertedId } = await api.addInvoice(invoice)
    history.push(`/invoice/${insertedId}`)
    dispatch({ type: END_LOADING })
    openSnackbar("Invoice created successfully");

}

export const updateInvoice = (id, invoice, history) => async (dispatch) => {
    dispatch({ type: START_LOADING })
    const data = await api.updateInvoice(id, invoice)
    history.push(`/invoice/${id}`)
    dispatch({ type: END_LOADING })
}

export const deleteInvoice = (id, data,openSnackbar) => async (dispatch) => {
    dispatch({ type: START_LOADING });
    const result = await api.deleteInvoice(id);
    await api.deletedInvoiceRecord(data);
    // console.log(data);
    //console.log(result)
    dispatch({ type: END_LOADING });
    dispatch({ type: DELETE })
    if (result) {
        openSnackbar("Invoice deleted successfully");
    }
}